import NavbarMain from '../components/NavbarMain';
import {getProvider, getMetadata, getImageUrls, getListings} from '../util/SacredUtils';
import { useWallet} from '@solana/wallet-adapter-react';
import { useState, useEffect } from 'react';
export default function Learnmore(props) {
  let wallet = useWallet();
  const [meta, setMeta]= useState(null);
  useEffect(() =>{
    if(!wallet || !wallet.connected){
      return;
    }
    //setReady(false);
    console.log("trying...");
    const fetchMetadata = async () => {
      console.log("inside the method... trying...");
      try{
        let listings = await getListings('froggos');
        console.log("listings", listings);
        let provider = await getProvider(wallet);
        console.log("provider is ", provider);
        let imageUrls = await getImageUrls(listings, provider.connection);
        //let metadata = await getMetadata("7V774kCKeaCwLdP3dtNvC7PXz1tx9DsXehtoFKvgcYer", provider.connection);
        //console.log("got it ", metadata);
        //let metatdatajson = await getImageUrl(metadata);
        console.log("imageUrls", imageUrls);
        //setMeta(metadata);
      }catch(e){
        console.log("trouble!!", e);
      }
      //setReady(true);
    }
    fetchMetadata().catch(console.error);
  }, [wallet.publicKey]);

  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`  }} className="container-fluid magic">
      <div className="container">
        <div className="row">
          <div className="col">
            <p>Learn More Twitter</p>
          </div>
          <div className="col">
            <p>Watch the Video</p>
          </div>
        </div>
      </div>
      <img src="/images/read_pyramid.png"/>
    </div>
    </>
  );
}
