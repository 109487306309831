import React from 'react';
import { useWallet, WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
import MembershipCheck from '../components/MembershipCheck';

require('@solana/wallet-adapter-react-ui/styles.css');

const wallets = [

  getPhantomWallet()
]

function SolanaWrapped(props) {
  return(
    <ConnectionProvider endpoint="https://api.devnet.solana.com">
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            { props.check!==false &&
            <MembershipCheck restricted={props.restricted}>{props.children}</MembershipCheck>
            }
            { props.check===false &&
                <>{props.children}</>
            }
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
);
}

export default SolanaWrapped;
