import NavbarMain from '../components/NavbarMain';
export default function Roadmap(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`  }} className="magic fs-5 container-fluid">

      <div className="row">
        <div className="col"><h1 className="text-white magic2">The Prophecy</h1>
        </div>

        <div className="col"><h1 className="text-white">aka The Roadmap</h1>
        </div>
      </div>
      <div className="row">
        <div className="col container text-white weird3">
          <p>And lo, the men who hold high places</p>
          <p>Must be the ones who start</p>
          <p>To mold a new reality</p>
          <p>Closer to the heart</p>
          <p className="fs-6">- Posthumus Peartus </p>
         </div>
         <div className="col"><img className="border" height="300" src="/images/SwedenborgsVision.png"/></div>
         <div className="col weird3 text-white">
           <p>Wherein are disclosed the arcana</p>
           <p>there foretold which have hitherto</p>
           <p>remained concealed</p>
         </div>
       </div>
      <div className="container">
        <div style={{ backgroundImage: `url("images/wtree2.png")`, backgroundRepeat: `no-repeat` }} className="row">
          <div className="col">
            <div className="container ">
              <div style={{ marginTop: `10px` }} className="row ">
                <div className="col-sm-6 text-white bg-black border">
                  <h1 className="text-white magic2">Phase 1</h1>
                </div>
              </div>
              <div style={{ marginTop: `10px` }} className="row ">
                <div className="col-sm-6 text-white bg-black border">
                  <p>What: Plug In Microwave</p>
                  <p>aka: launch this site</p>
                  <p>When: Feb 2022</p>
                  <p>Status: Complete</p>
                </div>
              </div>
              <div style={{ marginTop: `20px` }} className="row">
                <div className="col-sm-9 text-white bg-black border">
                  <p>What: Initial Drop of Magic Plebs and Magic Rings</p>
                  <p>When: April 2022</p>
                  <p>Status: On Track</p>
                </div>
              </div>
              <div style={{ marginTop: `50px` }} className="row ">
                <div className="col-sm-6 text-white bg-black border">
                  <h1 class="text-white magic2">Phase 2</h1>
                </div>
              </div>
              <div style={{ marginTop: `10px` }} className="row">
                <div className="col-sm-8 text-white bg-black border">
                  <p>What: Initial Token Drop</p>
                  <p>When: Q2 2022</p>
                  <p>Status: In The Microwave</p>
                </div>
              </div>
              <div style={{ marginTop: `50px` }} className="row">
                <div className="col-sm-8 text-white bg-black border">
                  <p>What: Metagame Begins, Wave 2 of Plebs</p>
                  <p>When: Q2 2022</p>
                  <p>Status: In The Microwave</p>
                </div>
              </div>
              <div style={{ marginTop: `50px` }} className="row ">
                <div className="col-sm-6 text-white bg-black border">
                  <h1 class="text-white magic2">Phase 3</h1>
                </div>
              </div>
              <div style={{ marginTop: `10px` }} className="row">
                <div className="col-sm-8 text-white bg-black border">
                  <p>What: Playable Beta of First Game</p>
                  <p>When: Q3 2022</p>
                  <p>Status: Incubating in the Dream Temple</p>
                </div>
              </div>
              <div style={{ marginTop: `10px` }} className="row">
                <div className="col-sm-8 text-white bg-black border">
                  <p>What: Pleb Connections</p>
                  <p>When: Q3 2022</p>
                  <p>Status: On The Counter</p>
                </div>
              </div>
              <div style={{ marginTop: `10px` }} className="row">
                <div className="col-sm-8 text-white bg-black border">
                  <p>What: Pleb Lemonade Stands Open</p>
                  <p>When: Q3 2022</p>
                  <p>Status: Waiting For The Bus</p>
                </div>
              </div>
              <div style={{ marginTop: `50px` }} className="row ">
                <div className="col-sm-6 text-white bg-black border">
                  <h1 class="text-white magic2">Phase 4</h1>
                </div>
              </div>
              <div style={{ marginTop: `10px` }} className="row">
                <div className="col-sm-8 text-white bg-black border">
                  <p>What: Building/Testing/Debugging</p>
                  <p>When: Q4 2022</p>
                  <p>Status: Visible on the horizon</p>
                </div>
              </div>
              <div style={{ marginTop: `50px` }} className="row ">
                <div className="col-sm-6 text-white bg-black border">
                  <h1 class="text-white magic2">Phase 5</h1>
                </div>
              </div>
              <div style={{ marginTop: `10px` }} className="row">
                <div className="col-sm-8 text-white bg-black border">
                  <p>What: First Game Launches</p>
                  <p>When: Q4 2022</p>
                  <p>Status: WAGMI</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>



    </div>
    </>
  );
}
