import NavbarMain from './components/NavbarMain';
import Unity, { UnityContext } from "react-unity-webgl";
import { useEffect } from "react";
const unityContext = new UnityContext({
  loaderUrl: "/game_build/mm_webgl.loader.js",
  dataUrl: "https://mm-game-build.s3.us-east-2.amazonaws.com/mm_webgl.data",
  //dataUrl: "/game_build/mm_webgl.data",
  frameworkUrl: "/game_build/mm_webgl.framework.js",
  codeUrl: "https://mm-game-build.s3.us-east-2.amazonaws.com/mm_webgl.wasm",
  //codeUrl: "/game_build/mm_webgl.wasm",
});
export default function GameDemo(props) {
  useEffect(function () {
    unityContext.on("debug", function (message) {
      console.log(message);
    });
  }, []);
  return(
    <div>
      <Unity
            unityContext={unityContext}
            devicePixelRatio={2}
            style={{
              height: 650,
              width: 1255,
              border: "2px solid black",
              background: "grey",
            }}
      />
    </div>);
}
