import NavbarMain from './components/NavbarMain';
import Unity, { UnityContext } from "react-unity-webgl";
import {getProvider, getMetadata, getImageUrl, getListings} from './util/SacredUtils';
import { useWallet} from '@solana/wallet-adapter-react';
import { useState, useEffect } from 'react';


export default function HighlanderNFT(props) {
  let wallet = useWallet();
  const unityContext = props.unityContext;

  const [progression, setProgression] = useState(0);
  const [nftCollection, setNftCollection] = useState(0);

    useEffect(function () {
      unityContext.on("progress", function (progression) {
        setProgression(progression);
      });
    }, []);

  useEffect(function () {
    unityContext.on("debug", function (message) {
      console.log(message);
    });
  }, []);


  function testUnityConnection(){
    console.log("testing", unityContext);
    unityContext.send("GameController", "RecordNFTDeath");
    unityContext.send("GameController", "SpawnEnemy", 'https://bafybeibfkekkn4cvxgtyaytwzkowlpllahct5v2o2ylanzs3molwg7axyu.ipfs.dweb.link/2216.png?ext=png');
  }
  async function spawnWave(){
    if(!wallet || !wallet.connected){
      return;
    }
    unityContext.send("GameController", "NewWave");
    //setReady(false);
    console.log("trying...");
    const fetchMetadata = async () => {
      console.log("inside the method... trying...");
      try{

        let listingJson = await getListings(nftCollection);
        console.log("listings", listingJson);
        let provider = await getProvider(wallet);
        console.log("provider is ", provider);
        //let imageUrls = await getImageUrls(listings, provider.connection);
        //export async function getImageUrls(listingJson, connection){
          //let urls =[];
          for(let i = 0; i < listingJson.length; i++) {
            let listing = listingJson[i];
            const tokenMint = listing.tokenMint;
            const metadata = await getMetadata(tokenMint, provider.connection);
            const url = await getImageUrl(metadata);
            unityContext.send("GameController", "SpawnEnemy", url);
          };
          //return urls;
        //}
        //let metadata = await getMetadata("7V774kCKeaCwLdP3dtNvC7PXz1tx9DsXehtoFKvgcYer", provider.connection);
        //console.log("got it ", metadata);
        //let metatdatajson = await getImageUrl(metadata);
        //console.log("imageUrls", imageUrls);
        //for(let i =0; i<imageUrls.length; i++){
        //  unityContext.send("GameController", "SpawnEnemy", imageUrls[i]);
        //}
        //setMeta(metadata);
      }catch(e){
        console.log("trouble!!", e);
      }
      //setReady(true);
    }
    fetchMetadata().catch(console.error);
  }

  return(
    <>
    <NavbarMain {...props}/>
    <div>
    {progression<1 && <span>Loading {progression * 100} percent...</span>}
    <input type="text" placeholder="NFT Collection" onChange={e => setNftCollection(e.target.value)} />
    <button onClick={spawnWave}>Spawn a bunch!</button>
      <Unity
            unityContext={props.unityContext}
            devicePixelRatio={2}
            style={{
              height: 650,
              width: 1255,
              border: "2px solid black",
              background: "grey",
            }}
      />
    </div>
  </>);
}
