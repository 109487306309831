import NavbarMain from '../components/NavbarMain';
export default function JFK(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`}} className="container-fluid text-white magic">
    <h1 className="text-white magic2">JFK's Brain Is Speaking To Us</h1>
    <div className="container">
      <p className="text-white fs-4 magic mx-5 ">JFK believed in humanity's future.  He believed that together we could accomplish great things.  His spirit is alive and well in Magic Microwave.</p>
    </div>

    <div className="container">
      Now the trumpet summons us again; not as a call to bear arms, though arms we need; not as a call to battle, though embattled we are; but a call to bear the burden of a long twilight struggle, year in and year out, “rejoicing in hope, patient in tribulation”; a struggle against the common enemies of man: tyranny, poverty, disease, and war itself.

      Can we forge against these enemies a grand and global alliance, North and South, East and West, that can assure a more fruitful life for all mankind? Will you join in that historic effort?
      <p className="text-center"><img height="600" src="/images/jfk3.gif"/></p>
    </div>
</div>
</>
);
}
