import { web3 } from '@project-serum/anchor';
import {getProvider, fetchPleb, findPlebAccount} from '../util/SacredUtils';
import NavbarPlebs from "./NavbarPlebs";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useWallet} from '@solana/wallet-adapter-react';
import { Link } from "react-router-dom";
function ShowPleb(props){

  let wallet = useWallet();
  let location = useLocation();
  let plebmint = '';


  const [pleb, setPleb]= useState(null);
  const [ringMint, setRingMint]= useState(null);
  const [ready, setReady]= useState(null);
  useEffect(() =>{
    console.log("ShowPleb useEffect", location.state.pleb);
    console.log("ShowPleb - ringMint", location.state.ringMint);
    //plebmint = location.state.plebmint;
    if(!wallet || !wallet.connected){
      return;
    }
    //if pleb passed in use it bro
    if(location.state.pleb){
      setPleb(location.state.pleb);
      setRingMint(location.state.ringMint);
      setReady(true);
    }else{
      setReady(false);
      const getPleb = async () => {
        let provider = await getProvider(wallet);
        let thePleb = await findPlebAccount(plebmint, wallet, provider);
        console.log("got it ", thePleb);
        setPleb(thePleb);
        setReady(true);
      }
      getPleb().catch(console.error);
    }
  }, [wallet.publicKey]);


  //do not submit
  const handleSubmit = (evt) => {
      evt.preventDefault();
      //alert(`Submitting Name ${name}`)
  }

  if(ready){
      return(
        <>
        <NavbarPlebs pleb={pleb} ringMint={ringMint} ringCount={props.ringCount} plebCount={props.plebCount}/>
        <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`  }} className="container-fluid bg-dark text-white">
          <form onSubmit={handleSubmit}>
          <div className="row magic">
            <div className="col container-sm text-dark">
              <div   style={{ backgroundImage: `url("images/purple_sq.png")`, overflow: `hidden`}} className="card">
                <img style={{objectFit: `scale-down`, objectPosition: `center top`}} className="border-2 card-img-top"  height="400" src={pleb.details.image}></img>
                <div className="card-body">
                  <p style={{backgroundColor: `#FF12DA`}}   className="card-header text-black">Name: {pleb.details.name}</p>
                </div>
              </div>
            </div>
            <div className="col container-sm">
            <p>Transcendental Attributes</p>
            <table className="table table-sm magic text-white">
              <thead className="weird3">
                <tr>
                  <th scope="col">Attribute</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Strength:</td><td>{pleb.strength}</td></tr>
                <tr><td>Activity:</td><td>{pleb.activity}</td></tr>
                <tr><td>Flow:</td><td>{pleb.flow}</td></tr>
                <tr><td>Approach:</td><td>{pleb.approach}</td></tr>
                <tr><td>Intensity:</td><td>{pleb.intensity}</td></tr>
                <tr><td>Sensitivity:</td><td>{pleb.sensitivity}</td></tr>
                <tr><td>Focus:</td><td>{pleb.focus}</td></tr>
              </tbody>
            </table>


              <p>Magic Ring Supply: {pleb.ring.ringSupply} / {pleb.ring.ringMintSupply}</p>
              {(!pleb.ring.claimed && pleb.ring.ringSupply> 0) &&
              <p><Link to="/forgering" state={{ plebAddress: pleb.address}}><button>CLAIM RIGHTFUL MAGIC RINGS</button></Link></p>}
              <Link className="p-3" to="/ringmgmt"
                      state={{pleb: pleb, ringAddress: pleb.ringAddress, ringMint: ringMint}}
                  ><button>RING MANAGEMENT</button></Link>
              <Link to="/plebworkshop"
                      state={{pleb: pleb, ringAddress: pleb.ringAddress, ringMint: ringMint}}
                  ><button>PLEB WORKSHOP</button></Link>
            </div>
            <div className="col">
              <img height="200" src="/images/microwave_idle.png"></img>
            </div>
              </div>
            <div className="progress w-75 m-2 bg-dark">
              <div style={{ width: `10%`}} className="progress-bar bg-success" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"><span className="weird">PLEB PROGRESS</span></div>
            </div>

        </form>
      </div>
      </>
    );
  }else{
    return(<div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `800px 800px`, minHeight: `95vh`  }} className="container-fluid bg-dark text-white">Loading</div>);
  }

}

export default ShowPleb;
