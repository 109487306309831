import NavbarMain from '../components/NavbarMain';
import ListRings from '../components/ListRings';
import EnsureWallet from '../components/EnsureWallet';
import { useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import {
  Program, Provider, web3
} from '@project-serum/anchor';





import { useWallet } from '@solana/wallet-adapter-react';
const { SystemProgram, Keypair } = web3;


function RingList(props) {
  const wallet = useWallet();
  console.log("useWallet wallet is ", wallet);
  return (
        <>
          <NavbarMain {...props}/>
          <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`  }} className="container-fluid bg-dark text-white">
            <EnsureWallet wallet={wallet}/>
            <div className="row">
              <div className="col">
                <p>Your Rings</p>

              </div>
              <div className="col">
                <p><span className="weird fs-5">Coming Soon: </span> The Pleb Workshop will have it's Grand Opening</p>
              </div>
            </div>
            <ListRings wallet={wallet}/>
        </div>
        </>

  );
}

export default RingList;
