import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import App from './App';
import Whitepaper from "./routes/whitepaper";
import Introvideo from "./routes/introvideo";
import SacredEagle from "./routes/sacredeagle";
import Roadmap from "./routes/roadmap";
import SolanaWrapped from "./routes/SolanaWrapped";
import PlebList from "./routes/PlebList";
import RingList from "./routes/RingList";
import ForgeRing from "./routes/forgering";
import SendRing from "./routes/sendring";
import RingMgmt from "./components/RingMgmt";
import PlebWorkshop from "./components/PlebWorkshop";
import Horselover from "./routes/horselover";
import Leaderboard from "./routes/Leaderboard";
import JFK from "./routes/jfk";
import Faqs from "./routes/faqs";
import GameDemo from "./GameDemo";
import HighlanderNFT from "./HighlanderNFT";
import ShowPleb from "./components/ShowPleb";
import Learnmore from "./routes/learnmore";
import Unity, { UnityContext } from "react-unity-webgl";
import reportWebVitals from './reportWebVitals';
window.Buffer = window.Buffer || require('buffer').Buffer;
const unityContext = new UnityContext({
  loaderUrl: "/highlander/highlanderNFT.loader.js",
  dataUrl: "/highlander/highlanderNFT.data",
  //dataUrl: "/game_build/mm_webgl.data",
  frameworkUrl: "/highlander/highlanderNFT.framework.js",
  codeUrl: "/highlander/highlanderNFT.wasm",
  //codeUrl: "/game_build/mm_webgl.wasm",
});
ReactDOM.render(
  <React.StrictMode>

  <BrowserRouter>
     <Routes>
         <Route path="/" element={<App />} />
         <Route path="whitepaper" element={<SolanaWrapped check={false} restricted={false}><Whitepaper /></SolanaWrapped>} />
         <Route path="artists" element={<SolanaWrapped check={false} restricted={false}><Introvideo /></SolanaWrapped>} />
         <Route path="pleblist" element={<SolanaWrapped><PlebList/></SolanaWrapped>} />
         <Route path="ringlist" element={<SolanaWrapped><RingList/></SolanaWrapped>} />
         <Route path="sacredeagle" element={<SolanaWrapped check={false} restricted={false}><SacredEagle /></SolanaWrapped>} />
         <Route path="roadmap" element={<SolanaWrapped restricted={false} check={false}><Roadmap /></SolanaWrapped>} />
         <Route path="forgering" element={<SolanaWrapped><ForgeRing/></SolanaWrapped>} />
         <Route path="sendring" element={<SolanaWrapped><SendRing/></SolanaWrapped>} />
         <Route path="ringmgmt" element={<SolanaWrapped><RingMgmt/></SolanaWrapped>} />
         <Route path="plebworkshop" element={<SolanaWrapped><PlebWorkshop/></SolanaWrapped>} />
         <Route path="faqs" element={<SolanaWrapped restricted={false} check={false}><Faqs/></SolanaWrapped>} />
         <Route path="horselover" element={<SolanaWrapped restricted={false}><Horselover /></SolanaWrapped>} />
         <Route path="leaderboard" element={<SolanaWrapped restricted={false}><Leaderboard /></SolanaWrapped>} />
         <Route path="jfk" element={<SolanaWrapped restricted={false} check={false}><JFK /></SolanaWrapped>} />
         <Route path="showpleb" element={<SolanaWrapped><ShowPleb/></SolanaWrapped>} />
         <Route path="gamedemo" element={<SolanaWrapped restricted={true}><GameDemo/></SolanaWrapped>} />
         <Route path="onlyone" element={<SolanaWrapped restricted={false} check={false}><HighlanderNFT unityContext={unityContext}/></SolanaWrapped>} />
         <Route path="alexetchdemo" element={<SolanaWrapped restricted={false} check={false}><GameDemo/></SolanaWrapped>} />
         <Route path="learnmore" element={<SolanaWrapped restricted={false} check={false}><Learnmore /></SolanaWrapped>} />
     </Routes>
 </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
