import { useWallet } from '@solana/wallet-adapter-react';
import { useState, useEffect, cloneElement } from 'react';
import {getProvider, checkMembership} from '../util/SacredUtils';
import EnsureWallet from './EnsureWallet';
import { Link } from "react-router-dom";
export default function MembershipCheck(props) {
  const wallet = useWallet();
  const [isMember, setIsMember] = useState(null);
  const [ready, setReady] = useState(null);
  const [ringCount, setRingCount] = useState(null);
  const [plebCount, setPlebCount] = useState(null);
  useEffect(() =>{
    console.log("----------------------MembershipCheck useEffect called rc:"+ringCount+" pc:"+plebCount, isMember);
    if(!wallet || !wallet.publicKey){
      console.log("wallet empty - returning");

      setIsMember(false);
      return
    }
    setReady(false);
    let runCheckMemberhip = async () => {
      if(isMember===true){
        setReady(true);
        return;
      }
      let provider = await getProvider(wallet);
      let result = await checkMembership(wallet, provider);
      console.log("checkMember", result);
      setPlebCount(result.plebs);
      setRingCount(result.rings);
      setIsMember((result.plebs>0 || result.rings>0));
      setReady(true);
    }
    //Call the async function
    runCheckMemberhip().catch(console.error);
    //Below is the cleanup
    return () => {
      setReady(true);
    }
  }, [wallet, wallet.publicKey]);
  if(!wallet || !wallet.publicKey){
    return(<><EnsureWallet wallet={wallet}/></>);
  }else{
    if(ready){
      if(isMember || props.restricted!==true){
        //props.ringCount = ringCount;
        //props.plebCount = plebCount;
        return (<>{cloneElement(props.children, { ringCount: ringCount, plebCount: plebCount })}</>);
      }else{
        return(
          <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`  }} className="magic fs-5 container-fluid text-white">
            <div style={{ backgroundImage: `url("images/Sacredeagle1.png")`, backgroundSize: `600px 600px`  }}>
              <div><Link to="/">Home Page</Link></div>
              <div><h1>Microwavers Only</h1></div>
              <div>Sorry, you are not yet a Member of Magic Microwave.</div>
              <div>If you think you maybe have selected the wrong trousers, change your wallet (i.e. select a different one), reload the page to try again.</div>
              <iframe src="https://giphy.com/embed/l4FGFIRAZYTpcKozS" width="480" height="362" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/reactionseditor-stop-motion-rip-l4FGFIRAZYTpcKozS">via GIPHY</a></p>
            </div>
          </div>
        );
      }
    }else{
      const num = Math.floor(Math.random() * 7);
      let msg = '';
      if(num==0){
        msg = "Awaken Fellow Sleepers";
      }else if(num==1){
        msg = "Greetings Fellow Slaves";
      }else if(num==2){
        msg = "The Moment Of Freedom is at hand";
      }else if(num==3){
        msg = "Cleanse your mind of lies";
      }else if(num==4){
        msg = "In eternity all is vision";
      }else if(num==5){
        msg = "Feel The Tremulations";
      }else if(num==6){
        msg = "Welcome Corporate Refugees";
      }else{
        msg = "Building For Eternity";
      }
      return(
        <>

          <div style={{backgroundColor: `#FF12DA`, marginBottom: `-10px`}} className="container-fluid weird2 text-center"><h1>Magic Microwave Is Loading....</h1></div>

        <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`  }} className="container-fluid">
          <div style={{ margin: `auto`, width:`90%` }}>

            <h1 className="weird2 text-white text-center">{msg}</h1>
          </div>
            <img style={{ marginTop: `-300px` }} src="/images/micro1.gif"/>
          </div>
          </>
        );
    }
  }
}
