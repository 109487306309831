import NavbarMain from '../components/NavbarMain';
import ListPlebs from '../components/ListPlebs';
import EnsureWallet from '../components/EnsureWallet';
import { useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import {
  Program, Provider, web3
} from '@project-serum/anchor';





import { useWallet } from '@solana/wallet-adapter-react';
const { SystemProgram, Keypair } = web3;


function PlebList(props) {
  const wallet = useWallet();
  console.log("useWallet wallet is ", wallet);
  return (
        <>
          <NavbarMain {...props}/>
          <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`  }} className="container-fluid bg-dark text-white">
            <EnsureWallet wallet={wallet}/>
            <div className="row">
              <div className="col">
                <p>The Plebs you own will show up here.</p>

              </div>
              <div className="col">
                <p><span className="weird fs-5">Coming Soon: </span> Plebs will be able to go to work for you!</p>
              </div>
            </div>
            <ListPlebs wallet={wallet} {...props}/>
        </div>
        </>

  );
}

export default PlebList;
