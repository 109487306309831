import { NavLink } from "react-router-dom";
import {DropdownButton, Dropdown} from "react-bootstrap";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {NavDropdown} from "react-bootstrap";
import {Navbar} from "react-bootstrap";
import SolanaWrapped from "../routes/SolanaWrapped";
require('@solana/wallet-adapter-react-ui/styles.css');

function NavbarMain(props) {
  return(

    <Navbar style={{backgroundColor: `#FF12DA`}} expand="lg" className="weird navbar-dark">
      <div className="container-fluid">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <a className="navbar-brand fs-2 weird2" href="/">MagicMicrowave</a>
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="navbar-nav fs-5">


            <NavLink className="nav-link" to="/whitepaper">"Whitepaper"</NavLink>
            <NavLink   className="nav-link" to="/roadmap">Prophecy</NavLink>
            <NavLink   className="nav-link" to="/artists">Artists</NavLink>
            <NavLink   className="nav-link" to="/gamedemo">Demo</NavLink>
            {props.plebCount > 0 &&
              <NavLink  className="nav-link " to="/pleblist">The Plebs</NavLink>
            }
            {props.ringCount > 0 &&
              <NavLink  className="nav-link " to="/ringlist">Rings</NavLink>
            }
            <DropdownButton className="mx-5 btn50" align="end" variant="dark" id="dropdown-basic-button" title="Cool Stuff">
              <Dropdown.Item><NavLink  className="nav-link text-black" to="/faqs">FAQs</NavLink></Dropdown.Item>
              <Dropdown.Item><NavLink  className="nav-link text-black" to="/leaderboard">Leaderboard</NavLink></Dropdown.Item>
              <Dropdown.Item><NavLink  className="nav-link text-black " to="/horselover">Horselover</NavLink></Dropdown.Item>
              <Dropdown.Item><NavLink  className="nav-link text-black " to="/jfk">JFK</NavLink></Dropdown.Item>
              <Dropdown.Item><NavLink  className="nav-link text-black " to="/sacredeagle">Sacred Eagle</NavLink></Dropdown.Item>
            </DropdownButton>

          </div>
        </Navbar.Collapse>
        { !props.wallet &&
          <div className="d-flex justify-content-end">
            <SolanaWrapped check={false}>
              <WalletMultiButton />
            </SolanaWrapped>
          </div>
        }
      </div>
    </Navbar>);
  }

  export default NavbarMain;
