import { NavLink } from "react-router-dom";
function NavbarPlebs(props) {
  return(
    <nav style={{backgroundColor: `#FF12DA`}} className="weird navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <a className="navbar-brand fs-2 weird2" href="/">MagicMicrowave</a>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav fs-5">

            <NavLink className="nav-link weird2" to="/pleblist">The Plebs</NavLink>
            {props.plebCount > 0 &&
            <>
            <NavLink className="nav-link" to="/showpleb"
              state={{pleb: props.pleb, ringMint: props.ringMint}}>Pleb Info</NavLink>

            <NavLink  className="nav-link" to="/ringmgmt"
              state={{pleb: props.pleb, ringMint: props.ringMint}}>Rings</NavLink>
              </>
            }
            { props.ringCount > 0 &&
              <NavLink  className="nav-link" to="/ringlist"
                state={{pleb: props.pleb, ringMint: props.ringMint}}>Rings</NavLink>
            }
            <NavLink  className="nav-link" to="/plebworkshop"
              state={{pleb: props.pleb, ringMint: props.ringMint}}>Workshop</NavLink>
          </div>
        </div>
      </div>
    </nav>);
  }

  export default NavbarPlebs;
