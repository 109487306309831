import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
require('@solana/wallet-adapter-react-ui/styles.css');
export default function EnsureWallet(props) {
  return (
    <>
    { !props.wallet.connected &&
        <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`,  minHeight: `99vh`, display: `flex`, justifyContent: `center` }}>
            <div className="mt-5">
              <WalletMultiButton />
              <p className="magic2 fs-1  text-white mt-5">Connect and Proceed</p>
            </div>

         </div>
    }
    </>
  );
  }
