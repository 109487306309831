import { web3 } from '@project-serum/anchor';
import {getProvider, sendRing, fetchRing, findRingAccounts} from '../util/SacredUtils';
import NavbarPlebs from "./NavbarPlebs";
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { useWallet} from '@solana/wallet-adapter-react';
import { Link } from "react-router-dom";
function RingMgmt(props){

  let wallet = useWallet();
  let location = useLocation();
  //console.log("locationstate huh", location.state);
  let pleb = location.state.pleb;
  //const ringAddress = location.state.ringAddress;
  const [sendRingAddress, setSendRingAddress] = useState('');
  const [ringAccounts, setRingAccounts] = useState(null);
  const [ringMint, setRingMint]  = useState(null);
  const [sendResult, setSendResult] = useState(null);
  const [ready, setReady]= useState(null);
  const [sending, setSending]= useState(false);
  useEffect(() =>{
    console.log("RingMgmt useEffect", location.state);

    if(!wallet || !wallet.connected){
      return;
    }
    setRingMint(location.state.ringMint);
    setReady(false);
    const getRingAccounts = async () => {
      let provider = await getProvider(wallet);

      let _ringAccounts = await findRingAccounts(location.state.ringMint, pleb.ringVault, provider);
      console.log("got them ", _ringAccounts);
      setRingAccounts(_ringAccounts);
      setReady(true);
    }
    getRingAccounts().catch(console.error);
  }, [wallet.publicKey]);

  const fireSendRing = () =>{

    console.log("Now send a ring to ", sendRingAddress);
    //console.log("e is ", e);
    let runSendRing = async () => {
      const provider = await getProvider(wallet);
      let ring = await fetchRing(pleb.ringAddress, wallet, provider);
      try{
        let result = await sendRing(ring, sendRingAddress, wallet, provider);
        setSendResult(result);
      }catch(e){
        console.log("Sending failed", e);
        setSendResult("Failed to send ring to "+sendRingAddress);
      }
    }
    //Call the async function
    console.log("-------------------------->got here...");
    runSendRing().catch(console.error);
  }

  //do not submit
  const handleSubmit = (evt) => {
      evt.preventDefault();
      //alert(`Submitting Name ${name}`)
  }

      if(ready){
      return(
        <>
        <NavbarPlebs pleb={location.state.pleb} ringMint={location.state.ringMint} ringCount={props.ringCount} plebCount={props.plebCount}/>
        <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px` , minHeight: `95vh` }} className="container-fluid magic bg-dark text-white">
        <div className="row">
          <div className="col">

              <div className="col container-sm">
                <p>Magic Ring Supply: {pleb.ring.ringSupply} / {pleb.ring.ringMintSupply}</p>
                {(!pleb.ring.claimed && pleb.ring.ringSupply> 0) &&
                <p><Link to="/forgering" state={{ plebAddress: pleb.address}}><button>CLAIM RIGHTFUL MAGIC RINGS</button></Link></p>}
                {(pleb.ring.claimed && pleb.ring.ringSupply>0) &&
                  <>
                <p className="weird3">The rings of {pleb.details.name} are true Microwave Rings.</p>
                <p><img height="200" src={pleb.ring.details.image}></img></p>
                <p>Address Here</p>
                <p>
                <input type="text" size="48"
                       value={sendRingAddress}
                      onChange={e => setSendRingAddress(e.target.value)}/></p>
                <div onClick={fireSendRing}>SEND A RING</div><br/>
                    <Link to="/showpleb"
                            state={{pleb: pleb, ringMint: ringMint}}
                        >BACK TO PLEB DETAILS</Link>
                </>
                 }
                 { sendResult &&
                  <div> RESULT: {sendResult}</div>
                 }
                 {(pleb.ring.claimed && pleb.ring.ringSupply==0) &&
                   <>
                   <p>The Rings have been distributed.</p>
                   <p><img height="200" src={pleb.ring.details.image}></img></p>
                   </>
                 }

              </div>

          </div>
          <div className="col">
            <p className="weird2 text-white fs-2">Ring Distribution</p>
            <table className="table table-sm magic text-white">
              <thead className="weird">
                <tr>
                  <th scope="col">Address</th>
                  <th scope="col">Number</th>
                  <th scope="col">*</th>
                </tr>
              </thead>
              <tbody>
              { ringAccounts.map((account, i) =>{
                  const faceMod = (account.uiAmount % 4);
                  return <tr key={i}>
                          <td>{account.walletAddress.toString()}</td>
                          <td>{account.uiAmount}</td>
                          <td><img width="20px" src={`/images/Mface`+faceMod+`.png`}/></td>
                        </tr>})
                }
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </>
    );
  }else{
    return(<div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `800px 800px`  }} className="container-fluid bg-dark text-white weird2">Loading
    <img style={{ marginTop: `-300px` }} src="/images/micro1.gif"/></div>);
  }

}

export default RingMgmt;
