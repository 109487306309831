import NavbarMain from '../components/NavbarMain';
export default function Faqs(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`}} className="container-fluid text-white magic">
    <p>Transcendental Attributes of the Magic Plebs</p>
    <div className="container fs-5">
    <p className="magic2 fs-1">Who Are the Plebs?</p>
    <p>The Plebs are the characters who inhabit Magic Microwave - the everyday people.  They will feature in many games that Magic Microwave makes, including the first game, Magic Monsters.
    <br/>Each pleb is unique - 1/1s - just like you and me :) <br/><br/>
    </p>
    <p className="magic2 fs-1">What can the Plebs do?</p>
    <p>Eventually Plebs will be able to do quite a lot!  Farming, dancing, mining, shopkeeping, exploring, adventuring, whatever we can think of :)</p>
    <p>Initially each Pleb can <u>claim a magic ring.</u>  <br/>
    These rings allow access to Magic Microwave universe, and let holders help with gleaming the Pleb.  <br/>
    (The rings can also be traded, given or I suppose sold)<br/>

    </p>
    <p>Starting in Phase 2 Plebs will be able to dig, as long as their digging animation is completed.
    </p>

    <p className="magic2 fs-1">What Do Pleb holders get?</p>
    <p>
      <li>Holders of Plebs at the time of the the token launch will be airdropped according to their associated gleaming schedule.</li>
      <li>Ten magic rings</li>
      <li>Right to mint 1 Land of the Realm.</li>
    </p>
    <p className="magic2 fs-1">What responsibilities do holders of Plebs have?</p>
    <p>Holders of Plebs are responsible for approving all aspects of gleaming - mostly animations and new images.<br/>
    The magic rings are intended to be given to people who can help with completing the Pleb's gleam schedule.
    </p>
    <p className="magic2 fs-1">What can Magic Rings do?</p>
    <p>Holders of Magic Rings can:
      <ul>
        <li>Earn gleams according to the associated Pleb gleaming schedule for approved submissions.</li>
        <li>Access and play the beta of Magic Monsters.  During play of the beta of Magic Monsters 200 additional Plebs will be randomly dropped.</li>
        <li>Earn Good Faith NFT (non-transferable) making them eligible for YOZ airdrop </li>
      </ul>
    </p>
    <p className="magic2 fs-1">What Are the Pleb Attributes?</p>
    <p>It is not clearly known.<br/>
    One report has claimed they are the following:<br/>
      <ul>
        <li>Power</li>
        <li>Light</li>
        <li>Unification</li>
        <li>Activity</li>
        <li>Flow</li>
        <li>Approach</li>
        <li>Adaptability</li>
        <li>Intensity</li>
        <li>Sensitivity</li>
        <li>Focus</li>
        <li>Persistence</li>
        <li>Logistics</li>
        <li>Creativity</li>
      </ul>
    </p>
    <p className="text-white magic2 fs-1 text-center">Are We Making  A Game Out of Making A Game?</p>
    <div className="row">
      <div className="col-4 mx-4 p-3">
        <p className="text-white fs-4 magic">Yes. Plebs have a lot of potential, but they aren't complete.  Holders help create the world by finishing the character, adding their own touch in the process.<br/></p>
      </div>
      <div className="col-6">
        <p className="text-center"><img height="300" src="/images/plebs/Hondo.png"/></p>
      </div>
    </div>
    <div className="row">
      <div className="col-4 mx-4 p-3">
        <p className="text-white fs-4 magic">You will inject life, spirit into it, just like Dr. Frankenstein did!<br/></p>
      </div>
      <div className="col-6">
        <p className="text-center"><img height="300" src="/images/plebs/hondo_walking.gif"/></p>
      </div>
    </div>
    <p className="magic2 fs-1">In my beginning is my end?</p>
    <p>Old men ought to be explorers</p>
    <p>Here and there does not matter</p>
    <p>We must be still and still moving</p>
    <p>Into another intensity</p>
    <p>For a further union, a deeper communion</p>
    <p>Through the dark cold and empty desolation,</p>
    <p>The wave cry, the wind cry, the vast waters</p>
    <p>Of the petrel and the porpoise. In my end is my beginning. </p>

    <p>-TS Eliot "East Coker"    </p>

    </div>
</div>
</>
);
}
