import { web3 } from '@project-serum/anchor';
import {getProvider, getOwnedPlebs} from '../util/SacredUtils';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
function ListPlebs(props){
  const [listItems, setListItems] = useState(null);
  const [ready, setReady]= useState(null);
  const [sendRingAddress, setSendRingAddress] = useState(null);
  useEffect(() =>{
    if(!props.wallet || !props.wallet.publicKey){
      console.log("wallet empty - returning");
      setListItems(<li>Scanning Wallet....</li>);
      return;
    }
    console.log("wallet is", props.wallet.publicKey.toString());



    setReady(false);
    const getTokens = async () => {
      const provider = await getProvider(props.wallet);
      console.log("*********************** checking for wallet: ",props.wallet.publicKey.toString());

      let plebList = await getOwnedPlebs(props.wallet, provider);

      const _listItems = [];
      if(plebList.length>0){
        _listItems.push(
            <div className="magic">
              <h2>Your Magic Plebs</h2>
              <p>You'll be able to pass them on to your children!</p>
            </div>);
      }else{
        _listItems.push(<div><h2>You Don't Have A Pleb in this wallet.</h2></div>);
        if(props.ringCount>0){
          _listItems.push(<div>
              <h3>Ahh, but you are a ring bearer.</h3>
              <p><Link to="/ringlist">Click Here to check the rings</Link></p>
              </div>);
        }
      }
      for (let i = 0, len = plebList.length; i < len; i++) {
      //accounts.forEach((item, i) => {
        console.log("----------------------------->>>>Loop:"+i);
        let info = plebList[i];
        console.log("pleb info is", info);
        info && _listItems.push(
          <div className="row magic">
            <div className="col container-sm text-dark">
              <div   style={{ backgroundImage: `url("images/purple_sq.png")`, overflow: `hidden`}} className="card">
                <img style={{objectFit: `scale-down`, objectPosition: `center top`}} className="border-2 card-img-top"  height="200" src={info.details.image}></img>
                <div className="card-body">
                  <p style={{backgroundColor: `#FF12DA`}}   className="card-header">Name: {info.details.name}</p>
                </div>
              </div>
            </div>
            <div className="col container-sm">

              <p>Pleb Mint: {info.plebmint.toString()}</p>
              {(!info.ring.claimed && info.ring.ringSupply> 0) &&
                <>
              <p>Unclaimed Magic Rings: {info.ring.ringSupply}</p>
              <p><Link to="/forgering" state={{ plebAddress: info.address}}><button>CLAIM RIGHTFUL MAGIC RINGS</button></Link></p>
              </>
              }
              {info.ring.claimed &&
                <>
              <p>The rings are yours to distribute as you see fit.</p>
              <p><img height="200" src={info.ring.details.image}></img></p>
              <p>
              <Link to="/showpleb"
                      state={{pleb:info, plebAddress: info.address, ringMint: info.ring.ringmint.toString()}}
                  ><button>SEE PLEB DETAILS</button></Link>
                  </p>
              </>
               }
            </div>
            <div className="col">
              <img height="200" src="/images/microwave_idle.png"></img>
            </div>
        </div>);

      }
      setReady(true);
      setListItems(_listItems);
      //await Promise.all(promises);

    }
    //Call the async function

    getTokens().catch(console.error);
  }, [props.wallet.publicKey]);
  if(ready){
    return(
      <div>{listItems}</div>
    );
  }else{
    return(<div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `800px 800px`  }} className="container-fluid bg-dark text-white weird2">Loading
    <img style={{ marginTop: `-300px` }} src="/images/micro1.gif"/></div>);
  }

}

export default ListPlebs;
