import NavbarPlebs from '../components/NavbarPlebs';
import {getProvider, sendRing, fetchRing} from '../util/SacredUtils';
import { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { useLocation } from "react-router-dom";
import {
  Program, Provider, web3
} from '@project-serum/anchor';
import { useWallet} from '@solana/wallet-adapter-react';

const { SystemProgram, Keypair } = web3;


function SendRing() {
  const wallet = useWallet();
  let location = useLocation();
  let ringAccount = false;
  let sendRingAddress = false;
  if(location){
    ringAccount = location.state.ringAccount;
    sendRingAddress = location.state.sendRingAddress;
  }
//2rmDXoGQZJ6yi4ncnP1qq8TtP5MPb4nCi1Qfn1jqwjKt
  console.log("useWallet wallet is ", wallet);
  console.log("useLocation ring is ", ringAccount);
  console.log("useLocation sendRingAddress is ", sendRingAddress);
  const [sendResult, setSendResult] = useState(null);
  useEffect(() =>{
    if(!wallet || !wallet.publicKey){
      console.log("wallet empty - returning");

      return;
    }
    if(!ringAccount || !sendRingAddress){
      return;
    }
    console.log("wallet is", wallet.publicKey.toString());



    let runSendRing = async () => {
      const provider = await getProvider(wallet);
      let ring = await fetchRing(ringAccount, wallet, provider);
      let result = await sendRing(ring, sendRingAddress, wallet, provider);
      setSendResult(result);
      console.log("send result is", result);
    }
    //Call the async function

    runSendRing().catch(console.error);
  }, [wallet.publicKey, ringAccount]);

  return (
        <>
          <NavbarPlebs/>
          <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`  }} className="magic container-fluid bg-dark text-white">
            <div>Let us forge in the sacred magic microwave!</div>
            <div>You tried to send a ring.</div>
            <div>The result is: {sendResult}</div>
        </div>
        </>

  );
}

export default SendRing;
