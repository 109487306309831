import logo from './logo.svg';
import './App.css';
import EnsureWallet from './components/EnsureWallet';
import NavbarMain from './components/NavbarMain';
import PlebGallery from './components/PlebGallery';
import { Link } from "react-router-dom";
//const programID = new PublicKey(idl.metadata.address);

function App(props){
  return (<><NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`,  minHeight: `99vh` }} className="container-fluid">
      <div className="row">
        <div className="col"><p className="magic">Greetings Fellow Slaves</p></div>
        <div className="col" style={{ margin: `auto`, width:`50%` }}>
          <img className="text-center mx-auto" width="400px" src="/images/Only.png"/>
        </div>
        <div className="col"><p className="magic">Pull Away Your Masks</p></div>
      </div>

    <div className="row">
      <div className="col">
      <img style={{ marginTop: `-100px` }} className="mx-auto d-block" height="600px" src="/images/Magic_microwave_small.png"/>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <div style={{ marginTop: `-100px` }} >
          <p className="text-white text-center magic fs-4">
          Magic Microwave is a collaborative game studio/art project, building out the Metaverse.
          </p>

      </div>
    </div>
    <div className="row" style={{ marginTop: `0px` }}>
      <div className="col">
          <div className="container-lg text-center">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe width="840" height="473" className="embed-responsive-item" src="https://www.youtube.com/embed/zdxkC0K-LPE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
        <p className="text-white text-center magic">Join JFK's brain! Embrace creativity! Reject soul-crushing!</p>
        <p className="text-white text-center magic fs-3">Seeking artist-collaborators!<br/>  <Link to="/artists">Click here to see the light</Link></p>
        <p className="text-white text-center magic">(put on your sunglasses :)  ) </p>
        <p className="text-white text-center magic2 fs-1">
        Currently in Phase 1
        </p>

        <p className="text-white text-center magic fs-3">
        Coming soon: The Magic Plebs
        </p>
        <p className="text-white text-center magic fs-4">
        The 100 + original inhabitants of Magic Microwave
        <br/>1/1 NFTs<br/>

        </p>


        <PlebGallery/>



    </div>
</div>
  </>);
}
export default App;
