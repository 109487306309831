import NavbarMain from '../components/NavbarMain';
import {getProvider, fetchPleb, fetchRing, claimRing, getMintOwnership} from '../util/SacredUtils';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Connection, PublicKey } from '@solana/web3.js';
import { useLocation } from "react-router-dom";
import {
  Program, Provider, web3
} from '@project-serum/anchor';
import { useWallet, WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';


require('@solana/wallet-adapter-react-ui/styles.css');
const opts = {
  preflightCommitment: "processed"
}


const { SystemProgram, Keypair } = web3;


function ForgeRing(props) {
  const wallet = useWallet();
  let location = useLocation();
  let plebAddress = false;
  if(location){
    plebAddress = location.state.plebAddress;
  }
  console.log("FORGERING props", props);
  console.log("FORGERING plebAddress ", plebAddress);
  const [ringMint, setRingMint] = useState(null);
  const [pleb, setPleb] = useState(null);
  const [ring, setRing] = useState(null);
  const [ringClaimed, setRingClaimed] = useState(null);
  const [ringClaimInProgress, setRingClaimInProgress] = useState(null);
  const [claimResult, setClaimResult] = useState(null);
  useEffect(() =>{
    console.log("----------------->FORGERING USE EFFECT");
    if(!wallet || !wallet.publicKey){
      console.log("wallet empty - returning");

      return;
    }
    if(!plebAddress){
      return;
    }
    console.log("FORGERING wallet is", wallet.publicKey.toString());



    let runClaimRing = async () => {
      const provider = await getProvider(wallet, opts);
      let plebPda = new web3.PublicKey(plebAddress);
      let _pleb = await fetchPleb(plebPda, wallet, provider);
      let _ring = _pleb.ring;
      setPleb(_pleb);
      setRing(_ring);
      if(_ring.claimed){
        setClaimResult("ALREADY CLAIMED");
        setRingClaimInProgress(false);
        return;
      }
      setRingMint(_ring.ringmint.toString());
      //let result = await getMintOwnership(pleb.plebmint, wallet, provider);
      console.log("88888888888888...............would call claimRing ....");
      //let result = await claimRing(_pleb, _ring, wallet, provider);
      //console.log("claim result is", result);
      setClaimResult("SUCCESS");
      setRingClaimInProgress(false);
    }
    //Call the async function
    if(!ringClaimInProgress){
      setRingClaimInProgress(true);
      runClaimRing().catch(console.error);
    }
    //Below is the cleanup
    return () => {
      setRingClaimInProgress(false);
    }

  }, [wallet.publicKey, plebAddress]);

  return (
        <>
          <NavbarMain/>
          <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`  }} className="magic container-fluid bg-dark text-white">
            { !wallet.connected &&
              <div style={{ display: 'flex', justifyContent: 'center', marginTop:'100px' }}>
                 <WalletMultiButton />
               </div>
            }
            {(!ringClaimInProgress && pleb!==null) &&
              <>
              <div className="weird3 text-center fs-1">Truly These Are Microwave Rings</div>
              <div className="container">
                <div style={{ backgroundImage: `url("images/open_mic.png")`, backgroundSize: `600px 600px`, backgroundRepeat: `no-repeat`, backgroundPosition: `center`}} className="mx-5 text-center">
                  <img style={{objectFit: `scale-down`, objectPosition: `center`}} className="border-2 card-img-top"  height="400" src={pleb.ring.details.image}></img>
                </div>
                <div className="mx-5">The result is: {claimResult}</div>
                <div className="mx-5">
                  <Link to="/showpleb"
                        state={{pleb:pleb, plebAddress: pleb.address, ringMint: ring.ringmint.toString()}}
                    ><button>SEE PLEB DETAILS</button></Link>
                    </div>
              </div>
             </>
            }
            {ringClaimInProgress &&
            <div><img style={{ marginTop: `-300px` }} src="/images/micro1.gif"/></div>
             }
        </div>
        </>

  );
}

export default ForgeRing;
