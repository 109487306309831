import NavbarMain from '../components/NavbarMain';
export default function SacredEagle(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundColor: `black`, backgroundSize: `1200px 1200px`, minHeight: `95vh` , backgroundRepeat: `no-repeat` }} className="container-fluid text-white">
    <div style={{ backgroundImage: `url("images/Sacredeagle1.png")`, backgroundSize: `1100px, 700px`, backgroundRepeat: `no-repeat`}} className="container-fluid text-white">
    <div style={{ margin: `auto`, width:`50%` }} className="magic">
      <h2 className="fs-1 weird"><b>The Sacred Eagle</b></h2>
      <div className="row mb-0 text-start fs-3">
        <p> It is said there are 14 ways</p>
        <p>to cook the Sacred Eagle.</p>
        <p>12 of these cannot be accomplished on Earth.</p>
        <p>One of them will result in madness.</p>
        <p>The last is available to all of us -</p>
        <p>It is happening as we speak.</p>
      </div>
      <div style={{ marginTop: `40px` }} className="row mb-0 fs-3">
        <p>Watch as the recipe is followed:</p>
        <p>Hear the billions buzzing</p>
        <p>Feel the spin</p>
        <p>The mysterious rubbering wave.</p>
      </div>
      <div style={{ marginTop: `40px` }} className="row mb-0 fs-3">
        <p>Face the radar and receive it clearly</p>
        <p>Heating, endlessly reversing, it passes through metal's heart</p>
        <p>carmelizing all in its path.</p>
      </div>
      <div style={{ marginTop: `40px` }} className="row mb-0 fs-3">
        <p>Now contemplate the distance as</p>
        <p>we ignite the univeral magnetron</p>
        <p>and together make our daily stand,</p>
        <p>In the magic light of the microwave.</p>
      </div>
    </div>
    <div style={{ margin: `auto`, width:`50%` }}>
      <img className="text-center mx-auto" src="/images/multimagic.png" width="300px"/>
    </div>
    <div>
    <p className="magic fs-3">The List of Things You Cannot Buy</p>
    <ul className="magic">
      <li>joy</li><li>awe</li><li>peace</li><li>tranquility</li><li>a sense of purpose</li></ul>
    </div>
    </div>
    </div>
    </>
  );
}
