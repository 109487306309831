import NavbarMain from '../components/NavbarMain';
export default function Whitepaper(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`  }} className="container-fluid magic">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3><a href="/Magic-Microwave-Whitepaper-v1-03.pdf">"whitepaper"</a></h3>
          </div>
          <div className="col">
            <h3><a href="/MM-Deck-v1-01.pdf">blackpaper</a></h3>
          </div>
        </div>
      </div>
      <img src="/images/read_pyramid.png"/>
    </div>
    </>
  );
}
