import NavbarMain from '../components/NavbarMain';
export default function Leaderboard(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`}} className="container-fluid text-white magic">
    <p>Ye Shall Know Them By Their Fruits</p>
    <p className="weird2 fs-1 text-center">KNOW.</p>

</div>
</>
);
}
