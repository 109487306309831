import { Connection } from '@solana/web3.js';
import { Provider } from '@project-serum/anchor';
import magplebIdl from '../idls/magicpleb.json';
import magringIdl from '../idls/magicring.json';
import * as anchor from '@project-serum/anchor';
import * as web3 from '@solana/web3.js';
import * as spltoken from '@solana/spl-token';
import * as metaplex from '@metaplex/js';
import { programs } from '@metaplex/js';
import { Metadata, MetadataKey, MasterEdition,
         Creator, MetadataDataData, CreateMetadata }
         from '@metaplex-foundation/mpl-token-metadata';

const MAGIC_RING_PROG_ID = '3d7Xh28onNUgXvxQ5z5rLc1WK1SxFxZhsNcZS5epi8xf';
const SYSTEM_OWNER = '11111111111111111111111111111111';
export async function getProvider(wallet) {
  /* create the provider and return it to the caller */
  /* network set to local network for now */
  //const network = "http://127.0.0.1:8899";
  const opts = {
    preflightCommitment: "processed"
  }
  //const network = "https://api.devnet.solana.com";
  const network = "https://api.mainnet-beta.solana.com";
  const connection = new Connection(network, opts.preflightCommitment);

  const provider = new Provider(
    connection, wallet, opts.preflightCommitment,
  );
  return provider;
}
/***
* For a given wallet, return the plebs they own
*/
export async function getOwnedPlebs(wallet, provider){
  const programKey = new web3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
  let response = await provider.connection.getParsedTokenAccountsByOwner(wallet.publicKey, { programId: programKey });

  let plebs = [];
  for (let i = 0, len = response.value.length; i < len; i++) {
    let account = response.value[i];
    try{
      let mint = account.account.data.parsed.info.mint;
      let info = await findPlebAccount(mint, wallet, provider);
      if(info!=null){
        plebs.push(info);
      }
    }catch(e){
      console.log("pleb not found", e);
    }
    console.log("getOwnedPlebs returning", plebs);
  }
  return plebs;
}


export async function checkMembershipBool(wallet, provider){
  const result = await checkMembership(wallet, provider);
  return (result.plebs>0 || result.rings>0);
}

export async function checkMembership(wallet, provider){
  //See if they own a Magic Ring
  let plebCount = await getOwnedPlebCount(wallet, provider);
  let ringCount = await getOwnedRingCount(wallet, provider);
  console.log("plebCount is ", plebCount);
  console.log("ringCount is ", ringCount);

  return {plebs: plebCount, rings: ringCount};
}

export async function getOwnedPlebCount(wallet, provider){
  const prefix = 'magpleb';
  const pleb_prog_id = 'BkRQtsB5GBm6yXRh7uWZDQwnvPWf8Ppm7yuoT18SJTsx';

  const plebProgKey = new web3.PublicKey(pleb_prog_id);
  const program = new anchor.Program(magplebIdl, plebProgKey, provider);

  let response =
    await provider.connection.getParsedTokenAccountsByOwner(wallet.publicKey,
      { programId: spltoken.TOKEN_PROGRAM_ID });
  let count = 0;
  console.log("checking these many:",response.value.length);
  console.log("for wallet", wallet.publicKey.toString());
  for (let i = 0, len = response.value.length; i < len; i++) {
    let account = response.value[i];
    let mint = account.account.data.parsed.info.mint;
    console.log("checking mint:", mint);
    const tokenKey = new web3.PublicKey(mint);
    const [pda, bump] = await web3.PublicKey.findProgramAddress(
          [
            Buffer.from(prefix, 'utf8'),
            tokenKey.toBuffer(),
          ],
          plebProgKey
        );
     //const plebAccount = new anchor.web3.PublicKey(pda.toString());
     let info = null;
     try{
       info = await program.account.magicPlebState.fetch(
         pda
       );
       let doesOwn = await getMintOwnership(tokenKey, wallet, provider);
       if(doesOwn>0){
         count++;
       }
     }catch(err){
       console.log("this mint is not a pleb, shouldn't be a surprise.", err);
     }

   }
   return count;

}

export async function getOwnedRingCount(wallet, provider){
  let rings = await getOwnedRings(wallet, provider, false);
  return rings.length;
}

export async function getOwnedRings(wallet, provider, getDetails){
  const prefix = 'magicring';
  const ring_prog_id = '3d7Xh28onNUgXvxQ5z5rLc1WK1SxFxZhsNcZS5epi8xf';

  const ringProgKey = new web3.PublicKey(ring_prog_id);
  const program = new anchor.Program(magringIdl, ringProgKey, provider);

  let response =
    await provider.connection.getParsedTokenAccountsByOwner(wallet.publicKey,
      { programId: spltoken.TOKEN_PROGRAM_ID });
  let rings = [];
  console.log("checking these many:",response.value.length);
  console.log("for wallet", wallet.publicKey.toString());
  for (let i = 0, len = response.value.length; i < len; i++) {
    let account = response.value[i];
    let mint = account.account.data.parsed.info.mint;
    console.log("checking mint:", mint);
    const tokenKey = new web3.PublicKey(mint);
    const [pda, bump] = await web3.PublicKey.findProgramAddress(
          [
            Buffer.from(prefix, 'utf8'),
            tokenKey.toBuffer(),
          ],
          ringProgKey
        );
     //const plebAccount = new anchor.web3.PublicKey(pda.toString());
     let info = null;
     try{
       info = await program.account.masterMagicRingState.fetch(
         pda
       );
       let doesOwn = await getMintOwnership(tokenKey, wallet, provider);
       if(doesOwn>0){
         if(getDetails){
           const details = await fetchPleb(info.magicpleb, wallet, provider);
           rings.push(details);
         }else{
           rings.push(info);
         }


       }
     }catch(err){
       console.log("this mint is not a ring, shouldn't be a surprise.", err);
     }

   }
   return rings;

}

export async function getMintOwnership(mint, wallet, provider){
  let ata = await findAta(mint, wallet.publicKey);
  console.log("ata is ", ata.toString());
  try{
    let tokenAmount = await provider.connection.getTokenAccountBalance(ata);
    return tokenAmount.value.uiAmount;
  }catch(err){
    console.log("error returned, no account maybe, setting to null", err);
    return null;
  }
}

export async function checkTokenAccountBalance(account, provider){
  try{
    let tokenAmount = await provider.connection.getTokenAccountBalance(account);
    return tokenAmount.value.uiAmount;
  }catch(err){
    console.log("error returned, no account maybe, setting to null", err);
    return null;
  }
}

//passed in mint is a string b/c ran into weird serialization issues
export async function findRingAccounts(mint, ringVault, provider){
  const mintKey = new web3.PublicKey(mint);
  console.log("getRingAccounts ringVault is", ringVault);
  try{
    let accounts = await provider.connection.getTokenLargestAccounts(mintKey);
    let results = [];
    //This returns the token account - which is nice - but we actually want
    //the wallet - there is probably a way to get this in one fetch
    for (let i = 0, len = accounts.value.length; i < len; i++) {
      const acc = accounts.value[i];
      if(acc.uiAmount > 0){
        const result = {}
        result.uiAmount = acc.uiAmount;
        result.tokenAccountAddress = acc.address;
        if(result.tokenAccountAddress.toString()!=ringVault){
          const info = await provider.connection.getParsedAccountInfo(acc.address);
          result.walletAddress = info.value.data.parsed.info.owner;

          results.push(result);
        }
      }

    }
    console.log("token accounts", accounts.value);
    return results;
  }catch(err){
    console.log("error returned, no accounts maybe, setting to empty list", err);
    return [];
  }
}

export async function findPlebAccount(mint, wallet, provider){
  console.log('trying to find pleb for mint', mint);
  const tokenKey = new web3.PublicKey(mint);

  const prefix = 'magpleb';
  const pleb_prog_id = 'BkRQtsB5GBm6yXRh7uWZDQwnvPWf8Ppm7yuoT18SJTsx';

  const plebProgKey = new web3.PublicKey(pleb_prog_id);
  const [pda, bump] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from(prefix, 'utf8'),
          tokenKey.toBuffer(),
        ],
        plebProgKey
      );
   const plebAccount = new anchor.web3.PublicKey(pda.toString());
   const program = new anchor.Program(magplebIdl, plebProgKey, provider);
   let info = null;
   try{
     info = await program.account.magicPlebState.fetch(
       pda
     );
   }catch(err){
     console.log("this mint is not a pleb, shouldn't be a surprise.", mint);
     return info;
   }
   console.log("found this at least it seems like it ", info);
   let doesOwn = await getMintOwnership(tokenKey, wallet, provider);
   console.log("doesOwn?", doesOwn);
   if(!doesOwn>0){
     return false;
   }
   console.log("pleb ring is ", info.ringAccount.toString());
   info.address = pda.toString();
   info.mintAddress = info.plebmint.toString();
   //const metaPda = await getMetadataPda(mint);
   /**
   const metadata = await getMetadata(mint, provider.connection, info);

   info.metadata = metadata.data.data;
   info.ring    = await fetchRing(info.ringAccount, wallet, provider);
   info.details = await loadMetadataJson(info.metadata.uri);
   info.height = info.details.attributes.find(att => att.trait_type=="height").value;
   info.weight = info.details.attributes.find(att => att.trait_type=="weight").value;
   info.normal = info.details.attributes.find(att => att.trait_type=="normal").value;
   info.address = pda.toString();

   return info;*/
   info  = await addPlebDetails(info, wallet, provider);
   info.ringAddress = info.ring.address.toString();
   return info;
}

export async function addPlebDetails(pleb, wallet, provider){
  const metadata = await getMetadata(pleb.plebmint, provider.connection, pleb);

  pleb.metadata = metadata.data.data;
  pleb.ring    = await fetchRing(pleb.ringAccount, wallet, provider);
  //do this b/c of react serialization issues
  pleb.ringVault = pleb.ring.ringvaultAccount.toString();
  pleb.details = await loadMetadataJson(pleb.metadata.uri);
  pleb.strength = getAttributeValue(pleb, "Strength");
  pleb.activity = getAttributeValue(pleb, "Activity");
  pleb.flow = getAttributeValue(pleb, "Flow");
  pleb.approach = getAttributeValue(pleb, "Approach");
  pleb.intensity = getAttributeValue(pleb, "Intensity");
  pleb.sensitivity = getAttributeValue(pleb, "Sensitvity");
  pleb.focus = getAttributeValue(pleb, "Focus");

  return pleb;

}

function getNftMetadata(address){

}

function getAttributeValue(pleb, attName){
  const attribute = pleb.details.attributes.find(att => att.trait_type==attName);
  if(attribute){
    return attribute.value;
  }else{
    return '';
  }
}

/**
 * Forge a magic ring
 */
 export async function forgeMagicRing(mint, wallet, provider){
   //Does the magic ring need it's own metadata?
 }


/**
* Creates a mint and transfers it to the wallet in context
*/
export async function createMint(wallet, provider){
  console.log("CREATING A MINT!", provider);


  //let userKeypair = wallet;
  //Ok - generate a temp keypair
  let userKeypair = web3.Keypair.generate();
  //This won't work on mainnet -- need to fund this somehow
  let fromAirdropSignature = await provider.connection.requestAirdrop(
    userKeypair.publicKey,
    web3.LAMPORTS_PER_SOL,
  );
  // Wait for airdrop confirmation
  await provider.connection.confirmTransaction(fromAirdropSignature);
  console.log('programid', spltoken.TOKEN_PROGRAM_ID);
  let mintAccount = await spltoken.Token.createMint(
    provider.connection,
    userKeypair,
    userKeypair.publicKey,
    null,
    0,
    spltoken.TOKEN_PROGRAM_ID
  );
  console.log("mintAccount is ", mintAccount.publicKey.toString());
  // Get/Create the Associated Account for the user to hold the NFT

  const tempFromAccount =
      await mintAccount.getOrCreateAssociatedAccountInfo(
          userKeypair.publicKey
      );

  // Mint 1 token to the user's associated account
  await mintAccount.mintTo(
      tempFromAccount.address,
      userKeypair.publicKey,
      [],
      1
  );

  // Reset mint_authority to null from the user to prevent further minting
  // TODO put this back -
  /**
  await mintAccount.setAuthority(
      mintAccount.publicKey,
      null,
      "MintTokens",
      userKeypair.publicKey,
      []
  );*/
  //Give the mint authority to the wallet
  await mintAccount.setAuthority(
      mintAccount.publicKey,
      wallet.publicKey,
      "MintTokens",
      userKeypair.publicKey,
      []
  );
  // create token account in wallet to hold token
  //get the token account of the toWallet Solana address, if it does not exist, create it
  const toTokenAccount = await mintAccount.getOrCreateAssociatedAccountInfo(
    wallet.publicKey,
  )

  console.log("ok - toTokenAccount is ", toTokenAccount);
  // Add token transfer instructions to transaction
  const transaction = new web3.Transaction().add(
    spltoken.Token.createTransferInstruction(
      spltoken.TOKEN_PROGRAM_ID,
      tempFromAccount.address,
      toTokenAccount.address,
      userKeypair.publicKey,
      [],
      1,
    ),
  );
  //let bh = await provider.connection.getRecentBlockhash("finalized");
  //transaction.recentBlockhash = bh.blockhash;
  //transaction.feePayer = userKeypair.publicKey;
  //let t2 = wallet.signTransaction(transaction);
  //const signature = await provider.connection.sendRawTransaction(transaction.serialize());
  // Sign transaction, broadcast, and confirm

  const signature = await web3.sendAndConfirmTransaction(
    provider.connection,
    transaction ,
    [userKeypair],
    {commitment: 'confirmed'},
  );
  console.log('SIGNATURE', signature);

  return mintAccount.publicKey.toString();

}


export async function getListings(symbol){
  //const response = await fetch('https://api-mainnet.magiceden.dev/v2/collections/'+symbol+'/listings');
  const response = await fetch('https://magic-eden.goodgoblin.workers.dev/corsproxy?collection='+symbol);
  console.log("getListings response is", response);
  const json = await response.json();
  return json;
}

export async function getImageUrls(listingJson, connection){
  let urls =[];
  for(let i = 0; i < listingJson.length; i++) {
    let listing = listingJson[i];
    const tokenMint = listing.tokenMint;
    const metadata = await getMetadata(tokenMint, connection);
    const url = await getImageUrl(metadata);
    urls.push(url);
  };
  return urls;
}




export async function getImageUrl(metadata){
  const response = await fetch(metadata.data.data.uri);
  //console.log(metadataJson);
  const metadatajson = await response.json();

  return metadatajson.image;
}

export async function getMetadata(mint, connection){
  const metaPda = await getMetadataPda(mint);
  console.log("metaPda is ",metaPda);
  const metadata = await Metadata.load(connection, metaPda);
  return metadata;
}

async function getMetadataPda(mint){
  console.log("getMetadataPda for mint", mint);
  const meta_prog_id = 'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s';
  const prefix = 'metadata';
  let tokenKey = new web3.PublicKey(mint);
  let metaKey = new web3.PublicKey(meta_prog_id);


  let [pda, bump] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from(prefix, 'utf8'),
          metaKey.toBuffer(),
          tokenKey.toBuffer()
        ],
        metaKey
      );
  return pda;
}

async function loadMetadataJson(uri){
  const response = await fetch(uri);
  const details = await response.json();
  return details;
  //const response = await fetch(uri).then((res) => res.json())
  //          .then((json) => { villager.details= json});
}

export async function findAta(mintKey, walletKey){
  console.log("finding ata for mintKey ", mintKey.toString());
  console.log("finding ata for wallet key ", walletKey.toString());
  let [ataAddress, bump] = await web3.PublicKey.findProgramAddress(
      [
        walletKey.toBuffer(),
        spltoken.TOKEN_PROGRAM_ID.toBuffer(),
        mintKey.toBuffer()
      ],
      spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
    );
  return ataAddress;

}
export async function fetchRing(pda, wallet, provider){
  const ringProgramKey = new web3.PublicKey('3d7Xh28onNUgXvxQ5z5rLc1WK1SxFxZhsNcZS5epi8xf');

  const pdaKey = new web3.PublicKey(pda);
  const program = new anchor.Program(magringIdl, ringProgramKey, provider);
  let info = await program.account.masterMagicRingState.fetch(
     pdaKey
   );
   console.log("fetchring mint is",info.ringmint.toString());
   //The ring supply is found in the vault.
   info.ringSupply = await checkTokenAccountBalance(info.ringvaultAccount, provider);
   info.ringMintSupply = await getTokenMintSupply(info.ringmint, provider);
   let metadata = await getMetadata(info.ringmint, provider.connection);
   info.metadata = metadata.data.data;
   info.address = pda;
   info.details = await loadMetadataJson(info.metadata.uri);
   console.log("fetchring details", info.details);
   return info;
}

export async function fetchPleb(pda, wallet, provider){
  console.log("fetchPleb is ", pda);
  const mpProgramKey = new web3.PublicKey('BkRQtsB5GBm6yXRh7uWZDQwnvPWf8Ppm7yuoT18SJTsx');
  const program = new anchor.Program(magplebIdl, mpProgramKey, provider);
  let info = await program.account.magicPlebState.fetch(
     pda
   );
   info.address = pda;
   info  = await addPlebDetails(info, wallet, provider);
   return info;

}

export async function getTokenMintSupply(mint, provider){
  const supply = await provider.connection.getTokenSupply(mint);
  console.log("supply is ", supply);
  return supply.value.uiAmount;
}

export async function sendRing(ring, toAddress, wallet, provider){
  let toWalletKey = new web3.PublicKey(toAddress);
  console.log("sendRing: ringmint is ", ring.ringmint);
  let toAccount = await findAta(ring.ringmint, toWalletKey);
  console.log("sendRing: toAccount is ", toAccount.toString());
   //HnyNFRoj8SLsuVRiN7SWuR7ZR5kLkLXP9oy3UmWSDkZz
  const receiverAccount = await provider.connection.getAccountInfo(toAccount);

  const instructions: web3.TransactionInstruction[] = [];
  //First need to make the ATA

  if(receiverAccount=== null){
    instructions.push(
      spltoken.Token.createAssociatedTokenAccountInstruction(
        spltoken.ASSOCIATED_TOKEN_PROGRAM_ID,
        spltoken.TOKEN_PROGRAM_ID,
        ring.ringmint,
        toAccount,
        toWalletKey,
        wallet.publicKey
      )
    );
  }
  instructions.push(
    spltoken.Token.createTransferInstruction(
      spltoken.TOKEN_PROGRAM_ID,
      ring.ringvaultAccount,
      toAccount,
      wallet.publicKey,
      [],
      1,
    ),
  );
  const transaction = new web3.Transaction().add(...instructions);

  let bh = await provider.connection.getRecentBlockhash("finalized");
  transaction.recentBlockhash = bh.blockhash;
  transaction.feePayer = wallet.publicKey;
  //let t2 = wallet.signTransaction(transaction);
  //const signature = await provider.connection.sendRawTransaction(transaction.serialize());
  // Sign transaction, broadcast, and confirm

  let sig = await wallet.signTransaction(transaction);
  console.log(transaction);
  const signature = await provider.connection.sendRawTransaction(sig.serialize());
  return signature;
}


//Claim a Ring if you have the right to it
export async function claimRing(pleb, ring, wallet, provider){
  //let wallet = context.wallet;
  let ringAccount = new web3.PublicKey(pleb.ringAccount.toString());
  let ata = await findAta(pleb.plebmint, wallet.publicKey);
  const ringProgramKey = new web3.PublicKey('3d7Xh28onNUgXvxQ5z5rLc1WK1SxFxZhsNcZS5epi8xf');


  const program = new anchor.Program(magringIdl, ringProgramKey, provider);
  let params = {
  accounts: {
    claimant: wallet.publicKey.toString(),
    magicringAccount: ringAccount.toString(),
    ringmint: ring.ringmint.toString(),
    user: wallet.publicKey.toString(),
    plebaccount: pleb.address.toString(),
    plebmint: pleb.plebmint.toString(),
    ringvaultAccount: ring.ringvaultAccount.toString(),
    plebtokenaccount: ata.toString(),
    systemProgram: web3.SystemProgram.programId.toString(),
    tokenProgram: spltoken.TOKEN_PROGRAM_ID.toString(),
    },
  };
  console.log("params is:", params);
  let result = await program.rpc.claimMasterRing(
      {
      accounts: {
        claimant: wallet.publicKey,
        magicringAccount: ringAccount,
        ringmint: ring.ringmint,
        user: wallet.publicKey,
        plebaccount: pleb.address,
        plebmint: pleb.plebmint,
        ringvaultAccount: ring.ringvaultAccount,
        plebtokenaccount: ata,
        systemProgram: web3.SystemProgram.programId,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
      },
  });
  return result;
}
