import { web3 } from '@project-serum/anchor';
import {getProvider, getOwnedRings} from '../util/SacredUtils';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
function ListRings(props){
  const [listItems, setListItems] = useState(null);
  const [ready, setReady]= useState(null);

  useEffect(() =>{
    if(!props.wallet || !props.wallet.publicKey){
      console.log("wallet empty - returning");
      setListItems(<li>Scanning Wallet....</li>);
      return;
    }
    console.log("wallet is", props.wallet.publicKey.toString());



    setReady(false);
    const getTokens = async () => {
      const provider = await getProvider(props.wallet);
      console.log("*********************** checking for wallet: ",props.wallet.publicKey.toString());

      let ringList = await getOwnedRings(props.wallet, provider, true);

      const _listItems = [];
      if(ringList.length>0){
        _listItems.push(<>
            <div className="magic">
              <h2>Welcome Ring Bearer</h2>
              <p>These rings grant you access to the Magic Microwave DAO</p>
            </div>
            <div className="row magic">
              <div className="col container-sm weird">
                <h2>Ring</h2>
              </div>
              <div className="col weird">
                <h2>Pleb</h2>
              </div>
              <div className="col weird">
                <h2>Pneuma</h2>
              </div>
              <div className="col weird">
                <h2>Workshop</h2>
              </div>
          </div>
        </>);
      }else{
        _listItems.push(<div><h2>You Don't Have A Magic Ring</h2></div>);
      }
      for (let i = 0, len = ringList.length; i < len; i++) {

        let info = ringList[i];

        console.log("ring info is", info);
        info && _listItems.push(
          <div className="row magic">
            <div className="col container-sm text-dark">
              <img height="200" src={info.ring.details.image}/>
            </div>
            <div className="col">
              <img height="200" src={info.details.image}/>
            </div>
            <div className="col">
              <h3 className="weird2">0</h3>
            </div>
            <div className="col">
            <p className="magic">Preview the </p>
              <Link to="/plebworkshop"
                      state={{pleb: info, ringAddress: info.ringAddress, ringMint: info.ring.ringmint.toString()}}
                  ><button>PLEB WORKSHOP</button></Link>
            </div>
        </div>);

      }
      setReady(true);
      setListItems(_listItems);
      //await Promise.all(promises);

    }
    //Call the async function

    getTokens().catch(console.error);
  }, [props.wallet.publicKey]);
  if(ready){
    return(
      <div>{listItems}</div>
    );
  }else{
    return(<div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `800px 800px`  }} className="container-fluid bg-dark text-white weird2">Loading
    <img style={{ marginTop: `-300px` }} src="/images/micro1.gif"/></div>);
  }

}

export default ListRings;
