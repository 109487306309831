import { web3 } from '@project-serum/anchor';
import {getProvider, fetchPleb, findPlebAccount} from '../util/SacredUtils';
import NavbarPlebs from "./NavbarPlebs";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useWallet} from '@solana/wallet-adapter-react';
import { Link } from "react-router-dom";
function PlebWorkshop(props){

  let wallet = useWallet();
  let location = useLocation();
  let plebmint = '';


  const [pleb, setPleb]= useState(null);
  const [ringMint, setRingMint]= useState(null);
  const [ready, setReady]= useState(null);
  useEffect(() =>{
    console.log("ShowPleb useEffect", location.state.pleb);
    console.log("ShowPleb - ringMint", location.state.ringMint);
    //plebmint = location.state.plebmint;
    if(!wallet || !wallet.connected){
      return;
    }
    //if pleb passed in use it bro
    if(location.state.pleb){
      setPleb(location.state.pleb);
      setRingMint(location.state.ringMint);
      setReady(true);
    }else{
      setReady(false);
      const getPleb = async () => {
        let provider = await getProvider(wallet);
        let thePleb = await findPlebAccount(plebmint, wallet, provider);
        console.log("got it ", thePleb);
        setPleb(thePleb);
        setReady(true);
      }
      getPleb().catch(console.error);
    }
  }, [wallet.publicKey]);

  if(ready){
      return(
        <>
        <NavbarPlebs pleb={pleb} ringMint={ringMint} ringCount={props.ringCount} plebCount={props.plebCount}/>
        <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`  }} className="container-fluid bg-dark text-white text-center">
          <div className="container-fluid align-items-left">
            <img height="200" src="/images/PlebWorker.gif"/>
            <span className="weird2 display-1">PLEB WORKSHOP</span>
            <img height="200" src="/images/micro1.gif"/>
          </div>
          <div className="row magic gy-5 gx-5 align-items-center">
            <div className="col-5 p-3 align-items-center">
              <div className="card w-50 text-center mx-auto">

                <img height="200"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-auto"><h1>SIDE PROFILE IMAGE GOES HERE</h1></div>
                <h5 class="card-title text-black">Coming Soon</h5>
              </div>
            </div>
            <div className="col-2 align-items-center">
              <div className="card text-center my-auto">

                <img height="120"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-1"><h1>PLEB NAME</h1></div>
                <h5 class="card-title text-black">You'll Be Able</h5>
            </div>
            </div>
            <div className="col-5 p-3 align-items-center">
              <div className="card w-50 text-center mx-auto">

                <img height="200"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-auto"><h1>DISTRESS IMAGE GOES HERE</h1></div>
                <h5 class="card-title text-black">To Help Finish</h5>
              </div>
            </div>
          </div>
          <div className="row magic gx-5 align-items-center">
            <div className="col-3 p-3 align-items-center">
              <div className="card text-center mx-1">

                <img height="200"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-auto"><h1>WALK ANIMATION GOES HERE</h1></div>
                <h5 class="card-title text-black">This Pleb</h5>
              </div>
            </div>
            <div className="col-6 p-3 "><img height="400" src={pleb.details.image}></img></div>
            <div className="col-3 p-3 align-items-center">
              <div className="card text-center mx-1">

                <img height="200"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-auto"><h1>IDLE ANIMATION GOES HERE</h1></div>
                <h5 class="card-title text-black">And Earn Telos</h5>
              </div>
            </div>

          </div>
          <div className="row magic  gy-5 gx-5">
            <div className="col-4 p-3 align-items-center">
              <div className="card text-center mx-auto">
                <img height="200"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-auto"><h1>TURN ANIMATION GOES HERE</h1></div>
                <h5 class="card-title text-black">By Completing</h5>
              </div>
            </div>
            <div className="col-4 p-3 align-items-center">
              <div className="card text-center mx-auto">
                <img height="200"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-auto"><h1>FARM ANIMATION GOES HERE</h1></div>
                <h5 class="card-title text-black">Images, Animations</h5>
              </div>
            </div>
            <div className="col-4 p-3 align-items-center">
              <div className="card text-center mx-auto">
                <img height="200"  src="images/purple_sq.png"/>
                <div className="card-img-overlay weird text-black my-auto"><h1>RUN ANIMATION GOES HERE</h1></div>
                <h5 class="card-title text-black">Sound Effects and more</h5>
              </div>
            </div>
          </div>
      </div>
      </>
    );
  }else{
    return(<div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `800px 800px`, minHeight: `95vh`  }} className="container-fluid bg-dark text-white">Loading</div>);
  }

}

export default PlebWorkshop;
