import NavbarMain from '../components/NavbarMain';
export default function Horselover(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`}} className="container-fluid text-white magic">
    <p>PKD on blockchain:</p>
    <div className="container">
The key to all this is memory — the trace deposits of the past, which in their pure form in the
Logos are the creators of immortality (retrieval and permanence). First we observe and/or
participate; this lays down memory traces in us; then collectively we can be utilized as storage
spools, memory centers forming over the millennia a total memory center (matrix).
  </div>
  <p className="my-3">PKD on what is valuable in society:</p>
  <div className="container">What I must realize is that it is a bourgeois prejudice to suppose that for something to have
worth, there must be a practical application. The ancient Greeks knew that pure [philosophical]
understanding for its own sake was, even just in terms of the quest, the highest value or activity of a
man: Homo sapiens: man who knows.</div>
  <p className="weird2 fs-1 text-center">LEARN.</p>

</div>
</>
);
}
