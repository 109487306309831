import NavbarMain from '../components/NavbarMain';
import { Link } from "react-router-dom";
export default function Introvideo(props) {
  return (
    <>
    <NavbarMain {...props}/>
    <div style={{ backgroundImage: `url("images/space.gif")`, backgroundSize: `600px 600px`, minHeight: `95vh`  }} className="container-fluid magic">
      <div className="row">
        <div className="col">
          <p className="text-white magic2 fs-1 text-center">The Microwave is under construction</p>
          <div className="row">
            <div className="col-10 mx-5 p-3">
              <p className="text-white fs-4 magic mx-5 ">Actively looking for artists who want to lend their vision!</p>
              <p className="text-white fs-5 magic mx-5 ">Please reach out on twitter: <a href="https://twitter.com/rightlingnod">@rightlingnod</a>  or email: <a href="mailto: info@magicmicrowave.xyz">info@magicmicrowave.xyz</a> if you think this would be cool, or want to learn more!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
        <h1 className="text-white magic2">Artist Count: 7</h1>
        <h1 className="text-white magic2">Artist Collabs So Far: 3</h1>
          <img height="400" src="/images/PlebWorker.gif"/>
        </div>
        <div className="col">
          <h1 className="text-white magic2"> What Would I Have to Do?</h1>
          <p className="text-white">At first, specifically, would love if you could draw a Plebs.</p>
          <p className="text-white magic">Even just one, to see if you think this is a good fit.</p>
          <p className="text-white magic">
            <figure className="figure">
              <img src="/images/plebs/Fred.png"/>
              <figcaption className="figure-caption">Like this guy, but in your own style</figcaption>
            </figure>
          </p>
          <p className="text-white magic">Doesn't have to be anything like this guy, it should be something original, completely out of your own mind :).</p>
          <p className="text-white magic">The plan is to launch when we get about 20 artists total, give or take.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1 className="text-white magic2"> What's do I get?</h1>
          <p className="text-white fs-4 magic mx-5 ">Collaboration NFT sales would split 50/50, with 50% going to fund the creation of the game</p>
          <p className="text-white fs-4 magic mx-5 ">Artists who join in early would also get "Founding Artist" level DAO coin percent.  You are here before the DAO is even formed!</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1 className="text-white magic2"> What's next?</h1>
          <p className="text-white fs-4 magic mx-5 ">Join the discord!</p>
          <p className="text-white fs-4 magic mx-5 ">You can read the <Link to="/whitepaper">"whitepaper"</Link>, but planning some really cool stuff.</p>
          <p className="text-white fs-4 magic mx-5 ">Collaborations coming in:
            <li>Land <img height="200" src="/images/land.png"/></li>
            <li>Rides <img height="200" src="/images/truck.png"/></li>
            <li>Buildings <img height="200" src="/images/Pizza_palace.png"/></li>
            <li>Sacred Images <img height="200" src="/images/question.png"/></li>
            <li>More! <img height="200" src="/images/duck_tank.png"/></li>
          </p>
          <p className="text-white fs-4 magic mx-5 ">Working on a playable web-based demo of the game right now :) </p>
        </div>
      </div>
    </div>
    </>
  );
}
