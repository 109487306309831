import galleryPlebs from '../json/pleb_gallery.json';
export default function PlebGallery() {
  let adjustedPlebs = [];
  let plebbles = galleryPlebs.plebs.map((x) => x);
  // Will a new array of arrays with 3 items
  while (plebbles.length){
    adjustedPlebs.push(plebbles.splice(0, 3))
  };

  let rows = [];
  console.log("adjustedPlebs", adjustedPlebs);
  adjustedPlebs.map((setOf3,i) =>(
      rows.push(<div key={i} className="row magic gy-5 gx-5 align-items-center">
        {setOf3.map((singlePleb,j) => (
          <div key={`pleb`+j} className="col-4 p-3 align-items-center">
            <img className="mx-auto d-block" height="300" src={singlePleb.image}/>
          </div>
        ))}
      </div>)
    ));
  console.log("rows", rows);
  return (<>
  <div className="container-fluid">
    { rows }
  </div>

</>);
}
